<template>
	<div class="pt-5">
		<b-row class="text-center">
			<template v-for="(item, index) in navLinks">
				<b-col
					v-if="
						item.requiresAdmin == isAdmin ||
						item.requiresEncastador == isEncastador ||
						item.requiresInvitado == isInvitado
					"
					cols="6"
					md="3"
					lg="2"
					:key="index"
					class="d-flex justify-content-center pb-5"
				>
					<!-- 10 -->
					<router-link :to="{ name: item.path }">
						<b-card class="clickable card-responsive background-icons-module" body-class="pl-0 pr-0">
							<div class="d-flex justify-content-center">
								<feather-icon
									size="70"
									class="w-90"
									:icon="item.icon"
									v-if="
										item.title != 'Pollos' &&
										item.title != 'Ejemplares' &&
										item.title != 'Encaste' &&
										item.title != 'Enfermeria'
									"
								/>
								<img
									src="@/assets/images/icons/pollo_b11.png"
									alt=""
									v-if="item.title == 'Pollos'"
									class="w-90"
									style="
										width: 70px;
										filter: invert(50%) sepia(64%) saturate(5407%) hue-rotate(228deg)
											brightness(98%) contrast(91%);
									"
								/>
								<img
									src="@/assets/images/icons/iconos-02.png"
									alt=""
									v-if="item.title == 'Ejemplares'"
									class="w-90"
									style="
										width: 70px;
										filter: invert(50%) sepia(64%) saturate(5407%) hue-rotate(228deg)
											brightness(98%) contrast(91%);
									"
								/>
								<img
									src="@/assets/images/icons/iconos-03.png"
									alt=""
									v-if="item.title == 'Encaste'"
									class="w-90"
									style="
										width: 70px;
										filter: invert(50%) sepia(64%) saturate(5407%) hue-rotate(228deg)
											brightness(98%) contrast(91%);
									"
								/>
								<img
									src="/training/icon.svg"
									alt=""
									v-if="item.title == 'Preparacion'"
									class="w-90"
									style="
										width: 70px;
										filter: invert(50%) sepia(64%) saturate(5407%) hue-rotate(228deg)
											brightness(98%) contrast(91%);
									"
								/>
								<img
									src="@/assets/images/icons/nursery.svg"
									alt=""
									v-if="item.title == 'Enfermeria'"
									class="w-90"
									style="
										width: 70px;
										filter: invert(50%) sepia(64%) saturate(5407%) hue-rotate(228deg)
											brightness(98%) contrast(91%);
									"
								/>
							</div>
							<h6 class="mt-2" style="font-size: 0.9rem">{{ item.title }}</h6>
						</b-card>
					</router-link>
				</b-col>
			</template>
		</b-row>

		<images-sliders-total />

		<loading-in-the-background />
	</div>
</template>

<script>
import ImagesSlidersTotal from "../amg/header-bookmarks/ImagesSlidersTotal.vue"
import LoadingInTheBackground from "../amg/header-bookmarks/LoadingInTheBackground.vue"

export default {
	name: "MenuBrain",
	components: {
		ImagesSlidersTotal,
		LoadingInTheBackground,
	},
	data() {
		return {
			menuHidden: this.$store.state.appConfig.layout.menu.hidden,
			navbarConfig: this.$store.state.appConfig.layout.navbar.type,
			navLinks: [
				{
					title: "Ejemplares",
					icon: "/images/icon-menu/ejemplares.png",
					path: "brain-specimens-main",
					requiresAdmin: false,
					requiresEncastador: false,
					requiresInvitado: false,
				},
				{
					title: "Encaste",
					icon: "/images/icon-menu/encaste.png",
					path: "brain-encaste-main",
					requiresAdmin: false,
					requiresEncastador: false,
					requiresInvitado: false,
				},
				{
					title: "Torneos",
					icon: "AwardIcon",
					path: "brain-tournament-main",
					requiresAdmin: false,
					requiresEncastador: false,
					requiresInvitado: false,
				},
				{
					title: "Preparacion",
					icon: "/training/icon.svg",
					path: "brain-preparacion-main",
					requiresAdmin: false,
					requiresEncastador: false,
					requiresInvitado: false,
				},
				{
					title: "Enfermeria",
					icon: "HeartIcon",
					path: "brain-nursery-main",
					requiresAdmin: false,
					requiresEncastador: false,
					requiresInvitado: false,
				},
				{
					title: "Administrativo",
					icon: "ArchiveIcon",
					path: "brain-administrative-main",
					requiresAdmin: false,
					requiresEncastador: false,
					requiresInvitado: false,
				},
				{
					title: "Otros",
					icon: "HelpCircleIcon",
					path: "brain-others-main",
					requiresAdmin: false,
					requiresEncastador: false,
					requiresInvitado: false,
				},
				{
					title: "Menu principal",
					icon: "ApertureIcon",
					path: "menu",
					requiresAdmin: false,
					requiresEncastador: false,
					requiresInvitado: false,
				},
			],
		}
	},
	computed: {
		layout() {
			return this.$store.getters["appConfig/layout"]
		},
		userModules() {
			return this.$store.getters["auth/currentUser"].moduls
		},
	},
	created() {
		this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true)
		this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "floating" })
	},
	destroyed() {
		this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
			type: this.navbarConfig,
		})
		this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden)
		this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical")
	},
}
</script>

<style lang="scss" scoped>
.card-responsive {
	.card-body {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	height: 10rem !important;
	h6 {
		margin-top: 0.6rem !important;
		margin-bottom: 0;
	}
}
</style>
